<template>
  <header class="edit_header">
    <button class="delete" @click.prevent="this.postDelete = true">Delete Page</button>
    <button class="cancel" @click.prevent="clearLocalstore(); this.$router.go(-1); this.$store.state.fromContentEdit = true; this.$store.state.postUploadFiles = null">Cancel</button>
    <button class="save" @click.prevent="[freeze ? savePost() : null]">Done</button>
  </header>
  <main class="main_v8 content_edit">

    <div v-if="!desktop" class="type_choose_mobile">
      <p @click="here">Page Type:</p>

      <div
        class="page_type"
        :class="{ active_pt: this.initialType === 'txt' }"
        @click.prevent="typeSwitch('txt')"
      >Normal</div>

      <div
        class="page_type"
        :class="{ active_pt: this.initialType === 'pdf' }"
        @click.prevent="typeSwitch('pdf')"
      >Pdf</div>

      <div
        class="page_type"
        :class="{ active_pt: this.initialType === 'video' }"
        @click.prevent="typeSwitch('video')"
      >Video</div>

    </div>

    <p v-if="!desktop" class="subtitle" :style="{color: this.color}" v-text="this.postSubtitle ? this.postSubtitle : 'Empty Subtitle'"></p>
    <h2 v-if="!desktop" class="title" v-text="this.title ? this.title : ''" @click="here"></h2>
    
    <div class="edit_button" @click="optionToggle = !optionToggle"></div>

    <!-- <textfield v-if="this.class === 'txt_box'" class="textfield_edit" :forEdit="forEdit" id="edited"></textfield> -->
    <div class="txt_edit" v-if="this.initialType === 'txt'">
      <editor v-model="this.html" @paste="tmcePaste"
        api-key="no-api-key"
        :init="{
          //toolbar_mode: 'wrap',
          menubar: false,
          plugins: 'lists link image imagetools codesample nonbreaking paste',
          toolbar: 'undo redo | image | codesample | alignleft aligncenter alignright | bullist numlist | bold underline italic | h1 h2 h3',
          object_resizing: 'img',
          branding: false,
          file_picker_types: 'file image media',
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800'); @import url('/mce_editor.css'); body { font-family: Inter; }; ol,ul { padding-left: 20px;}`,
          image_uploadtab: true,
          paste_data_images: true,
          codesample_languages: [
            { text: 'HTML/XML', value: 'markup' },
            { text: 'JavaScript', value: 'javascript' },
            { text: 'TypeScript', value: 'typescript' },
            { text: 'CoffeeScript', value: 'coffeescript' },
            { text: 'CSS', value: 'css' },
            { text: 'SCSS', value: 'scss' },
            { text: 'Less', value: 'less' },
            { text: 'PHP', value: 'php' },
            { text: 'PHP Template', value: 'php-template' },
            { text: 'Ruby', value: 'ruby' },
            { text: 'Python', value: 'python' },
            { text: 'Python REPL', value: 'python-repl' },
            { text: 'Java', value: 'java' },
            { text: 'C', value: 'c' },
            { text: 'C#', value: 'csharp' },
            { text: 'C++', value: 'cpp' },
            { text: 'Scala', value: 'scala' },
            { text: 'Bash', value: 'bash' },
            { text: 'Kotlin', value: 'kotlin' },
            { text: 'Markdown', value: 'markdown' },
            { text: 'Perl', value: 'perl' },
            { text: 'R', value: 'r' },
            { text: 'SQL', value: 'sql' },
            { text: 'Diff', value: 'diff' },
            { text: 'Json', value: 'json' },
            { text: 'Objective-C', value: 'objective-c' },
            { text: 'Shell Session', value: 'shellsession' },
            { text: 'Visual Basic .NET', value: 'vb' },
            { text: 'Go', value: 'go' },
            { text: 'Lua', value: 'lua' },
            { text: 'Rust', value: 'rust' },
            { text: 'Swift', value: 'swift' },
            { text: 'Web Assembly', value: 'wasm' },
            { text: 'GraphQL', value: 'graphql' },
            { text: 'Makefile', value: 'makefile' },
            { text: 'YAML', value: 'yaml' },
            { text: '1C:Enterprise', value: '1c' },
            { text: 'ARM Assembly', value: 'armasm' },
            { text: 'BASIC', value: 'basic' },
            { text: 'Delphi', value: 'delphi' },
            { text: 'Django', value: 'django' },
            { text: 'HTTP', value: 'http' },
          ],
          // images_upload_url: 'postAcceptor.php',
          image_advtab: true,
          imagetools_cors_hosts: ['localhost:8080'],
          imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
          content_css: '/content.css',
          importcss_append: true,
          paste_preprocess: function (pl, o) {
            // Content string containing the HTML from the clipboard
            o.content = smart_paste(o.content); //todo fix and use again
          },
          images_upload_handler: this.tinymceImageUploadHandler,
        }"
      />
    </div>

    <div v-if="this.initialType == 'pdf'" class="pdf_edit">
      <span v-if="this.src != '' && this.src != undefined" class="delete-upload" @click="deletePDF = true"></span>
<!--      <pdff v-if="this.src != ''" :src="this.src"></pdff>-->
      <PSPDFKitContainer v-if="this.src != '' && this.src != undefined" :pdf-file="this.src" @loaded="handleLoaded" />
      <DropZonePdf
        v-if="this.src == '' || this.src == undefined"
        :index="0"
        :height="150"
        :docid="this.docid"
        @drop.prevent="drop"
        @change="selectedFile"
        @uploadCompleted="uploadComplete"
        @uploadStarted='uploadStarted'
      />
    </div>

    <div v-if="this.initialType === 'video'" class="video_edit">
      <div v-if="this.src != '' && this.src != undefined" class="delete_video" @click="deleteVID = true">
        <span></span>
      </div>
      <div v-if="this.src != '' && this.src != undefined" class="video_box">
        <iframe 
          :src = "this.src"
          style="border: none"
          height="480"
          width="800"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"
        ></iframe>
      </div>
      <div v-if="this.src == '' || this.src == undefined" id="videoloader" >
        <DropZone  :docid="this.docid" @drop.prevent="drop" @change="selectedFile" @uploadCompleted="uploadComplete" @uploadStarted='uploadStarted' />
      </div>
    </div>

    <div class="updown page_info" :class="{active_info: !optionToggle}">
      <span class="options_toggle" @click="[!desktop ? [optionToggle = !optionToggle] : null]">
        <span v-if="this.initialType == 'txt'">PAGE EDIT</span>
        <span v-if="this.initialType == 'pdf'">PDF PAGE EDIT</span>
        <span v-if="this.initialType == 'video'">VIDEO PAGE EDIT</span>
      </span>

      <div class="page_type_desktop" v-if="desktop">
        <p class="subheader">Page Type</p>
        <div class="page_type" :class="{active_pt: this.initialType === 'txt'}" @click.prevent="typeSwitch('txt')">Normal</div>
        <div class="page_type" :class="{active_pt: this.initialType === 'pdf'}" @click.prevent="typeSwitch('pdf')">Pdf</div>
        <div class="page_type" :class="{active_pt: this.initialType === 'video'}" @click.prevent="typeSwitch('video')">Video</div>
      </div>

      <div class="tag">
        <p>Title</p>
        <input type="text" v-model="this.title">
      </div>

      <div class="tag">
        <p>Subtitle or page #</p>
        <input type="text" v-model="this.postSubtitle">
      </div>

      <div class="tag wtf" v-if="this.imageEditor">
        123{{ this.imageEditor }}321
          <imgx :src="imageEdit.slice(4).slice(0, -1)" :imageEditor="true"
                @editedImageUploadCompleted="editedImageUploadComplete"
                @editedImageClosed="editedImageClose"></imgx>
        432
      </div>

      <!-- <div>
        <p class="subheader"><span></span> Hashtags</p>

        <div class="hashtags">
          <div v-for="(tag, index) in tags" :key="index"><span v-text="tag" @click="removeTags(index)"></span></div>
        </div>

        <div class="add_label" @click="addTag = true">+ ADD</div>
      </div> -->

      <div v-if="this.initialType == 'txt'" style="position: relative; height: auto;">
        <div class="uploader">
          <DropZoneContentEdit v-if="this.filesrc == ''" :index="0" :height="150" :docid="this.docid" @drop.prevent="drop" @change="selectedFile" @uploadCompleted="uploadComplete" @uploadStarted='uploadStarted' />
        </div>
        
        <p class="subheader">Files</p>
        <div class="field">
          <div class="files">
            <div class="doc" v-for="(doc, index) in $store.state.postUploadFiles" :key="index" :class="doc[1]">
              <a :href="doc[3]" target="_blank" :download="doc[0]">{{doc[0]}}</a>
              <span class="cross" @click="removeFiles(index)"></span>
            </div>

            <div class="add_img_label" @click="uploadContentEdit">+ ADD</div>

          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <shade v-if="confirm || postDelete || optionToggle || titleWarning || deletePDF || deleteVID || emptyVID || emptyPDF"
      @click.prevent="confirm = false; postDelete = false; optionToggle = false; titleWarning = false; deletePDF = false; deleteVID = false; emptyVID = false; emptyPDF = false;"></shade>
    </transition>

    <div v-if="titleWarning" class="title_warning">
      <h3>Error - No Title for Page</h3>
      <p>You must enter a page title in order to save the page.  The subtitle is optional, but can be used for helpful user hints, like “Step 1”.
      </p>
      <div class="btns">
        <btn class="button" v-text="'OK'" @click.prevent="titleWarningDelay"></btn>
      </div>
    </div>

    <div v-if="emptyPDF || emptyVID" class="title_warning">

      <h3 v-if="emptyPDF">Missing PDF File Warning</h3>
      <h3 v-if="emptyVID">Missing File Warning</h3>

      <p v-if="emptyPDF">You can’t save PDF page without PDF file.</p>
      <p v-if="emptyVID">You can’t save Video page without Video.</p>

      <div class="btns">
        <btn class="button" v-text="'OK'" @click.prevent="emptyPDF = false; emptyVID = false"></btn>
      </div>
    </div>

    <transition name="fade">
      <div v-if="confirm" class="delete_anything">
        <h3>Content Deletion Warning <span @click.prevent="this.confirm = false"></span></h3>
        <div class="trash_can"></div>
        <p class="warn">
          If you change the page type, any content you have created in the current page type will be DELETED. Please make sure you want to make this choice before changing the page type.
          <br/><br/>
          If you are sure, select the green button below.
        </p>
        <div class="buttons">
          <btn class="button" v-text="'Change Page Type'" @click.prevent="deleteChange" style="padding: 0px;"></btn>
          <btn class="button secondary" v-text="'Cancel Changes'" @click.prevent="this.confirm = false"></btn>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="postDelete || deletePDF || deleteVID" class="delete_anything">
        <h2 v-if="postDelete">Content Deletion Warning</h2>
        <h2 v-if="deletePDF">Document Deletion Warning</h2>
        <h2 v-if="deleteVID">Video Deletion Warning</h2>
        <div class="trash_can"></div>
        <p v-if="postDelete" class="warn">
          If you delete the page, any text, images or other content included in the page will be DELETED.
          <br/><br/>
          If you are sure, select the green button below.
        </p>
        <p v-if="deletePDF" class="warn">
          This document WILL BE DELETED!
          <br/><br/>
          If you are sure you want to delete, select the green button below.
        </p>
        <p v-if="deleteVID" class="warn">
          This video WILL BE DELETED!
          <br/><br/>
          If you are sure you want to delete, select the green button below.
        </p>
        <div class="buttons">
          <btn v-if="postDelete" class="button" v-text="'Delete Page'" @click.prevent="confirmDeletePage"></btn>
          <btn v-if="deletePDF || deleteVID" class="button" v-text="'Delete'" @click.prevent="deleteSRC(), deletePDF = false, deleteVID = false"></btn>
          <btn class="button secondary" v-text="'Cancel'" @click.prevent="postDelete = false, deletePDF = false, deleteVID = false"></btn>
        </div>
      </div>
    </transition>

    <!-- <div class="add_tag" v-if="addTag">
      <h2>Add New Tag</h2>
      <input type="text" v-model="newTagName" placeholder="Enter a tag">
      <div class="btns">
        <btn class="button secondary" v-text="'cancel'" @click="addTag = false; newTagName = ''"></btn>
        <btn class="button" v-text="'add tag'" :class="{'disabled' : disabled}" @click="addNewTag()"></btn>
      </div>
    </div> -->
  </main>
</template>

<script>
import {defineAsyncComponent, ref} from 'vue';
import pdff from 'vue3-pdf';
import btn from '../components/buttons/button.vue';
import shade from '../components/shade/shade.vue';
import '../assets/js/highlight/highlight';
import PSPDFKitContainer from '../components/pspdfkit/PSPDFKitContainer';

const DropZone = defineAsyncComponent(
  () => import('../components/dropzones/cloudflare_video.vue'),
);

const DropZonePdf = defineAsyncComponent(
  () => import('../components/dropzones/cloudflare_pdf.vue'),
);

const DropZoneContentEdit = defineAsyncComponent(
    () => import('../components/dropzones/cloudflare_attachments.vue'),
);
import imgx from '../components/imageeditor/image.vue';

import Editor from '@tinymce/tinymce-vue';
import '../assets/js/tinymce/tinymce';
// Theme
import '../assets/js/tinymce/themes/silver/theme'

// icons
import '../assets/js/tinymce/icons/default'

// Skins
import '../assets/js/tinymce/skins/ui/oxide/skin.css'
import '../assets/js/tinymce/skins/content/default/content.css'

// Plugins
// importing the plugin js.
import '../assets/js/tinymce/plugins/paste';
import '../assets/js/tinymce/plugins/advlist';
// import '../assets/js/tinymce/plugins/autolink';
// import '../assets/js/tinymce/plugins/link';
import '../assets/js/tinymce/plugins/image';
import '../assets/js/tinymce/plugins/lists';
import '../assets/js/tinymce/plugins/charmap';
import '../assets/js/tinymce/plugins/hr';
// import '../assets/js/tinymce/plugins/anchor';
// import '../assets/js/tinymce/plugins/spellchecker';
// import '../assets/js/tinymce/plugins/searchreplace';
// import '../assets/js/tinymce/plugins/code';
import '../assets/js/tinymce/plugins/codesample';
// import '../assets/js/tinymce/plugins/highlight';
// import '../assets/js/tinymce/plugins/fullscreen';
// import '../assets/js/tinymce/plugins/media';
import '../assets/js/tinymce/plugins/nonbreaking';
// import '../assets/js/tinymce/plugins/table';

export default {
  name: 'HelpViev',
  inject: ['apiPath'],
  components: {
    DropZone,
    DropZonePdf,
    DropZoneContentEdit,
    btn,
    shade,
    pdff,
    'editor': Editor,
    PSPDFKitContainer,
    imgx,
  },
  data() {
    return {
      currentUrl: '',
      uploading: false,
      imageEditor: false,
      imageEdit: '',
      secondary: true,
      optionToggle: false,
      desktop: false,
      // phone: false,
      tags: [],
      list: this.$store.state.book.data,
      target: this.$store.state.contentIndex,
      color: '',
      confirm: false,
      initialType: '',
      futureType: '',
      title: '',
      html: '',
      postSubtitle: '',
      files: [],
      views: '',
      likes: '',
      comments: '',
      share: '',
      src: '',
      class: '',
      isUploading: false,
      filesrc: '',
      addTag: false,
      newTagName: '',
      freeze: true,
      tooLong: false,
      windowWidth: window.innerWidth,
      typeGoTo: '',
      postDelete: false,
      pdfFile: "/pspdfkit-web-demo.pdf",
      titleWarning: false,
      deletePDF: false,
      deleteVID: false,
      emptyVID: false,
      emptyPDF: false,
    };
  },
  watch: {
    html(value) {
      localStorage.html = value
    },
    title(value) {
      localStorage.title = value
    },
    postSubtitle(value) {
      localStorage.postSubtitle = value
    },
  },
  computed: {
    viewType() {
      return this.$store.state.contentType;
    },
    forEdit() {
      return this.html;
    },
  },
  created() {
    this.docid = this.$route.params.id;
    this.idx = this.docid;
    const position = this.target;
    // console.log(position)
    // if (this.$store.state.book.data[position].isNew) {
    //   localStorage.isNew = true;
    // } else {
    //   localStorage.isNew = false;
    // }
    // console.log(this.$store.state.book.data[position].id, 'ID')
    if (this.$store.state.book.data[position] !== undefined) {
      this.initialType = this.$store.state.book.data[position].name;
      localStorage.initialType = this.$store.state.book.data[position].name;
      this.class = this.$store.state.book.data[position].class;
      localStorage.class = this.$store.state.book.data[position].class;

      this.html = this.$store.state.book.data[position].html;
      if (this.html === undefined) {
        localStorage.html = '';
      } else {
        localStorage.html = this.$store.state.book.data[position].html;
      }

      this.title = this.$store.state.book.data[position].title;
      if (this.title === undefined) {
        localStorage.title = '';
      } else {
        localStorage.title = this.$store.state.book.data[position].title;
      }

      this.postSubtitle = this.$store.state.book.data[position].subtitle;
      if (this.postSubtitle === undefined || this.postSubtitle == 'null' || this.postSubtitle == null) {
        localStorage.postSubtitle = '';
      } else {
        localStorage.postSubtitle = this.$store.state.book.data[position].subtitle;
      }

      if (this.$store.state.postUploadFiles === null || this.$store.state.postUploadFiles === undefined) {
        localStorage.postUploadFiles = JSON.stringify([]);
      } else {
        localStorage.postUploadFiles = JSON.stringify(this.$store.state.postUploadFiles);
      }

      this.color = this.$store.state.book.category.color;
      if (this.$store.state.book.category.color == null || this.$store.state.book.category.color == undefined) {
        localStorage.color = JSON.stringify([]);
      } else {
        localStorage.color = this.$store.state.book.category.color;
      }

      this.views = this.$store.state.book.data[position].views;
      if (this.views === undefined) {
        localStorage.views = -1;
      } else {
        localStorage.views = this.$store.state.book.data[position].views;
      }

      this.likes = this.$store.state.book.data[position].likes
      if (this.likes === undefined) {
        localStorage.likes = -1;
      } else {
        localStorage.likes = this.$store.state.book.data[position].likes;
      }

      this.comments = this.$store.state.book.data[position].comments;
      if (this.comments === undefined) {
        localStorage.comments = -1;
      } else {
        localStorage.comments = this.$store.state.book.data[position].comments;
      }

      this.share = this.$store.state.book.data[position].share;
      if (this.share === undefined) {
        localStorage.share = false;
      } else {
        localStorage.share = this.$store.state.book.data[position].share;
      }

      this.tags = this.$store.state.book.data[position].tags;
      if (this.tags === undefined || this.tags === null) {
        this.tags = [];
        localStorage.tags = '';
      } else {
        localStorage.tags = JSON.stringify(this.$store.state.book.data[position].tags);
      }
      this.id = this.$store.state.book.data[position].id;
      localStorage.id = this.$store.state.book.data[position].id;

    } else {
      this.initialType = localStorage.initialType;

      this.class = localStorage.class;
      
      if (localStorage.html == 'undefined') {
        this.html = '';
      } else {
        this.html = localStorage.html;
      }

      this.title = localStorage.title;

      if (localStorage.postUploadFiles === 'undefined' && this.class == 'txt_box') {
        this.$store.state.postUploadFiles = JSON.parse(localStorage.postUploadFiles);
      }
      if (localStorage.postSubtitle == 'undefined') {
        this.postSubtitle = '';
      } else {
        this.postSubtitle = localStorage.postSubtitle;
      }
      this.color = localStorage.color;

      this.views =  JSON.parse(localStorage.views);

      this.likes = JSON.parse(localStorage.likes);

      this.comments = JSON.parse(localStorage.comments);

      this.share = JSON.parse(localStorage.share);

      if (localStorage.tags === '') {
        this.tags = [];
      } else {
        this.tags = JSON.parse(localStorage.tags);
      }
      
      this.id = localStorage.id;
    }
    
    if(this.class=='txt_box')
    {
      if (this.$store.state.book.data[position] !== undefined) {
        this.src = this.$store.state.book.data[position].html;
        if (this.$store.state.book.data[position].html == undefined) {
          localStorage.html = '';
        } else {
          localStorage.html = this.$store.state.book.data[position].html;
        }
        localStorage.src = '';
      } else {
        this.src = localStorage.html;
      }
    }
    else
    {
      if (this.$store.state.book.data[position] !== undefined) {
        this.src = this.$store.state.book.data[position].src;
        localStorage.src = this.$store.state.book.data[position].src;
      } else {
        this.src = localStorage.src;
      }
      // this.src = this.$store.state.book.data[position].src;
    }
  },
  beforeMount() {
    this.ifDesktop();
    // console.log(localStorage.initialType)
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.ifDesktop();
    });
    this.docid = this.$route.params.id;
    
    if (this.title == '' || this.title == undefined || this.title == null) {
      this.optionToggle = false
    }
  },
  methods: {
    here() {
      console.log('this.$store.state.book', this.$store.state.book)
      // console.log(this.target)
    },
    ifDesktop() {
      if (window.innerWidth > 970 && window.innerWidth > window.innerHeight) {
        this.desktop = true;
        this.optionToggle = false;
      } else {
        this.desktop = false;
      }
    },
    // ifPhone() {
    //   if (window.innerWidth <= 600 && window.innerWidth < window.innerHeight) {
    //     this.phone = true;
    //   } else if (window.innerHeight <= 600 && window.innerWidth > window.innerHeight) {
    //     this.phone = true;
    //   } else {
    //     this.phone = false;
    //   }
    // },
    titleWarningDelay() {
      this.titleWarning = false;
      if (!this.desktop) {
        setTimeout(() => {
          this.optionToggle = true;
        }, "300")
      }
    },
    confirmDeletePage() {
      console.log('delete page and go to Book page');
      let scope = this;
      if (scope.id !== undefined) {
        this.sendFetch(
            'entity/post/delete',
            {
              "postid": scope.id
            }
        ).then(() => {
          this.freeze = true;
          this.$store.commit('pageSaved');
          this.$router.go(-1);
        });
      } else {
        this.$router.go(-1);
      }
    },
    handleLoaded(instance) {
      console.log("PSPDFKit has loaded: ", instance);
    },
    smart_paste(str) {
      str = str.replaceAll(/(?:class|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
      str = str.replaceAll(/(?:id|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
      if (str.includes('https://www.youtube.com/') || str.includes('https://youtube.com/') || str.includes('https://youtu.be/')) {
        str = str.replaceAll(/(?<!["'`])((?:http|https|ftp):\/\/\S+)(?!["'`])/gm, `<a href='$&' target='_blank' rel='nofollow'>$&</a>`);
      }
      str = str.replaceAll(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gm, ``);
      str = str.replaceAll(/(?:<script[^>]*)(?:(?:\/>)|(?:>.*?<\/script>))/gm, ``);
      str = str.replaceAll(/(?:<style[^>]*)(?:(?:\/>)|(?:>.*?<\/style>))/gm, ``);
      str = str.replaceAll(/\bwidth="(\d+)"/g, ``);
      str = str.replaceAll(/\bheight="(\d+)"/g, ``);
      str = str.replaceAll(/position:(fixed)/gm, ``);
      str = str.replaceAll(/position:( fixed)/gm, ``);
      return str;
    },
    uploadContentEdit() {
      let fileUpload = document.getElementsByClassName('dropzone_files_contentedit');
      console.log("in uploadContentEdit",fileUpload);
      fileUpload[0].click();
    },
    deleteSRC() {
      this.src = "";
    },
    clearLocalstore() {
      let keysToRemove = ['html','title','likes','views','share','comments','files','initialType','postUploadFiles','tags','id','postSubtitle','class','src','color'];

      for (let key of keysToRemove) {
        localStorage.removeItem(key);
      }
    },
    uploadStarted(path,index) {
      this.uploading = true;
      let url = '';
      url = index;
      url = path;
      this.currentUrl = url;
      console.log("upload started="+this.currentUrl);
    },
    uploadComplete(index, currentUrl = this.currentUrl) {
      if (index >= 0) {
        if(this.initialType == 'video') {
          this.src = currentUrl;
          //this.src = document.getElementById('objUrl').innerHTML;
          this.uploading = false;
          this.uploadEmpty = false;
          this.initialType = 'video';
        } else if (this.initialType == 'pdf') {
          this.src = this.currentUrl;
          console.log("URL:",this.src);
          this.uploading = false;
          this.uploadEmpty = false;
          this.initialType = 'pdf';
          this.pdfFile = this.src;
          if (this.src && this.src.startsWith('blob:')) {
            window.URL.revokeObjectURL(this.pdfFile);
          }
          this.pdfFile = window.URL.createObjectURL(this.pdfFile);
        }
      } else {
        console.log("STINKY PETE:",index)
        this.src = '';
        this.uploading = false;
        this.uploadEmpty = false;
      }
      if (this.src != '' && this.src != undefined && this.src != null) {
        localStorage.src = this.src;
      }
    },
    updatePath(path) {
      this.path = path;
    },
    drop() {

    },
    selectedFile() {

    },
    tmcePaste(e) {
      this.freeze = false;
      setTimeout(() => {
        let imgTags = e.currentTarget.getElementsByTagName('img');
        for (let imgTag of imgTags) {
          if (!imgTag.outerHTML.includes('<img width="') && imgTag.src.includes('data:image/')) {
            imgTag.outerHTML = imgTag.outerHTML.replace('<img', '<img width="' + imgTag.width + '" height="' + imgTag.height + '"'
                + 'style="width:'+imgTag.width+'px;height:'+imgTag.height+'px;"'
            );
          }
        }
        this.freeze = true;
      }, "1000");
    },
    tinymceImageUploadHandler(blobInfo, success, failure, progress) {
      // let xhr = new XMLHttpRequest();
      // xhr.open('GET', url);
      // xhr.upload.onprogress = function (e) {
      //   progress(e.loaded / e.total * 100);
      // };
      // xhr.onload = function() {
      //   let reader = new FileReader();
      //   reader.onloadend = function() {
      //     success(reader.result);
      //   }
      //   reader.readAsDataURL(xhr.response);
      // };
      // xhr.onerror = function () {
      //   failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
      // };
      // xhr.responseType = 'blob';
      // xhr.send();
      //console.log('file', blobInfo.blob(), blobInfo.filename());
      //success(blobInfo.blob());wtf
      const reader = new FileReader();

      reader.onload = function() {
        const base64String = reader.result;
        //console.log('wtf1', this.imageEditor);
        //this.optionToggle = false;
        success(base64String);
        //this.imageEditor = true;
      };

      reader.readAsDataURL(blobInfo.blob());
    },
    editedImageUploadComplete(newUrl) {
      if (newUrl !== undefined && newUrl !== null && newUrl !== '') {
        this.imageEdit = 'url(' + newUrl + ')';
        this.imageEditor = false;
      } else {
        console.log('Image is not uploaded');
      }
    },
    editedImageClose() {
      this.imageEditor = false;
    },
    check(e) {
      const el = e.target;
      const type = el.getAttribute('name');
      if (type === 'views') {
        if (el.checked) {
          this.views = 0;
        } else {
          this.views = -1;
        }
      }
      if (type === 'likes') {
        if (el.checked) {
          this.likes = 0;
        } else {
          this.likes = -1;
        }
      }
      if (type === 'comments') {
        if (el.checked) {
          this.comments = 0;
        } else {
          this.comments = -1;
        }
      }
      if (type === 'share') {
        if (el.checked) {
          this.share = true;
        } else {
          this.share = false;
        }
      }
    },

    deleteChange() {
      this.html = '';
      this.src = '';
      this.confirm = false;
      this.secondary = true;
      // this.postSubtitle = '';
      this.views = -1;
      this.likes = -1;
      this.comments = -1;
      this.share = false;
      this.contentType = this.initialType;
      this.currentUrl = '';
      localStorage.initialType = this.futureType;
      if (this.futureType == 'pdf') {
        this.class = "pdf_box";
        localStorage.class = "pdf_box";
        // localStorage.title = "";
      } else if(this.futureType == 'video') {
        this.class = "video_box";
        localStorage.class = "video_box";
        // localStorage.title = "";
      } else if(this.futureType == 'txt') {
        localStorage.removeItem('html');
        this.html = '';
        this.class = "txt_box";
        this.localStorage = "txt_box";
        this.localStorage = "";
      }
      this.initialType = this.futureType;
      this.$store.commit('contentTypeEdit', this.initialType);
    },
    getImageSize(dataURL) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = function() {
          reject(new Error("Failed to load image."));
        };
        img.src = dataURL;
      });
    },
    async savePost() {
      this.freeze = false;
      setTimeout(() => {
        this.freeze = true;
      }, "3000")
      const postType = this.initialType;
      console.log(postType);
      if(postType === 'video')
      {
          //this.src = document.getElementById('objUrl').innerHTML;
          this.src = this.src.replace("upload","iframe");
      }
      else if(postType === 'pdf')
      {
        if (this.src == "") {
          if (document.getElementById('objUrlPdf') == null) {
            this.emptyPDF = true;
          } else {
            this.src = document.getElementById('objUrlPdf').innerHTML;
          }
        }
        else if (this.src == null) {
          console.log('111111111111111111111111111111')
        }
      }
      else
      {
        this.src = "";
      }
      
      // const postTitle = this.title;
      if (this.target === '') {
        this.target = this.$store.state.book.data.length;
      }
      let txt = "";
      let content = {};
      if (postType === 'txt') {
        const isEmpty = false; //document.getElementById('edited').querySelector('.ProseMirror').firstChild.classList.contains('is-empty');
        txt = this.html;
        // console.log(txt);
        // txt = document.getElementById('edited').querySelector('.ProseMirror').innerHTML;
        this.$store.state.book.data[this.target] = {};
        //if (postTitle === '') {
        //  this.titleWarning = true;
        //  this.optionToggle = false;
        //  this.freeze = true;
        //  return;
        //}
        if (txt === '' || isEmpty) {
          alert('Page body can not be empty!');
          this.freeze = true;
          return;
        // } if (txt === '' && postTitle === '') {
        } if (txt === '') {
          alert('Page body and title can not be empty!');
          this.freeze = true;
          return;
        }
        // this.$store.state.book.data[this.target].html = JSON.stringify(txt.replace(/'/g, '"'))
        this.$store.state.book.data[this.target].html = txt.replace(/'/g, '"')
        if (this.$store.state.postUploadFiles !== undefined) {
          this.$store.state.book.data[this.target].files = this.$store.state.postUploadFiles;
        } else {
          this.$store.state.book.data[this.target].files = [];
        }
        this.$store.state.book.data[this.target].class = 'txt_box';
        this.$store.state.book.data[this.target].name = 'txt';
        // this.$router.go(-1);
      }
      
      if (postType === 'pdf') {
        this.$store.state.book.data[this.target] = {};
        // if (postTitle === '') {
        //   this.titleWarning = true;
        //   this.freeze = true;
        //   return;
        // }
        this.class = 'pdf_box';
        this.$store.state.book.data[this.target].class = 'pdf_box';
        this.$store.state.book.data[this.target].name = 'pdf';
        this.$store.state.book.data[this.target].src = this.src;
        // this.$router.go(-1);
      } 
      
      if (postType === 'video') {
        this.$store.state.book.data[this.target] = {};
        // if (postTitle === '') {
        //   this.titleWarning = true;
        //   this.freeze = true;
        //   return;
        // }
        if (this.src === '') {
          this.emptyVID = true;
          this.freeze = true;
          return;
        }
        this.class = 'video_box';
        this.$store.state.book.data[this.target].class = 'video_box';
        this.$store.state.book.data[this.target].name = 'video';
        this.$store.state.book.data[this.target].src = this.src;
        // this.$router.go(-1);
      }

      this.clearLocalstore();

      this.$store.state.book.data[this.target].subtitle = this.postSubtitle;
      this.$store.state.book.data[this.target].title = this.title;
      this.$store.state.book.data[this.target].name = this.initialType;
      this.$store.state.book.data[this.target].views = this.views;
      this.$store.state.book.data[this.target].likes = this.likes;
      this.$store.state.book.data[this.target].comments = this.comments;
      this.$store.state.book.data[this.target].share = this.share;
      this.$store.state.book.data[this.target].tags = this.tags;

      content = this.$store.state.book.data[this.target];
      this.$store.state.postUploadFiles = [];

      this.$store.commit('changedPage');
      if (postType === 'txt') {
        let editorDom = new DOMParser().parseFromString(this.html, 'text/html');
        let linksTags = editorDom.getElementsByTagName('a');
        for (let linksTag of linksTags) {
          linksTag.setAttribute('target', '_blank');
        }
        this.html = editorDom.body.innerHTML;
        content.html = editorDom.body.innerHTML;
        if ('serviceWorker' in navigator) {
          let imgTags = editorDom.getElementsByTagName('img');
          let base64ImgTags = [];
          let base64ImgText = [];
          let base64ImgInfo = [];
          for (let imgTag of imgTags) {
            if (imgTag.getAttribute('src')?.includes('data:image/')) {
              base64ImgTags.push(imgTag);
              base64ImgText.push(imgTag.getAttribute('src'));
              base64ImgInfo.push(await this.getImageSize(imgTag.getAttribute('src')));
            }
          }
          if (base64ImgText.length > 0) {
            let links = [];
            fetch(`${this.apiPath}cloudflareGetUploadLinksImages?count=${base64ImgText.length}&token=${this.$store.state.usertoken}`
            ).then((res) => res.json()).then((response) => {
              links = response;
              let uploadLinks = response;
              for (let key = 0; key < links.length; key++) {
                let link = links[key].replace('https://upload.','https://') + '/public'; //`public` - can be changed to `thumb` if little one needed
                base64ImgTags[key].setAttribute('alt', 'processing...');
                base64ImgTags[key].setAttribute('src', link);
                if (!base64ImgTags[key].outerHTML.includes('<img width="')) {
                  base64ImgTags[key].outerHTML = base64ImgTags[key].outerHTML.replace('<img', '<img width="' + base64ImgInfo[key].width + '" height="' + base64ImgInfo[key].height + '"'
                      + 'style="width:'+base64ImgInfo[key].width+'px;height:'+base64ImgInfo[key].height+'px;"'
                  );
                }
              }

              if (uploadLinks.length > 0) {
                let data =
                    {
                      ['imgText']: base64ImgText,
                      ['links']: uploadLinks,
                      ['docId']: this.docid,
                    };
                navigator.serviceWorker.addEventListener('message', event => {
                    console.log(event.data);
                });
                navigator.serviceWorker.ready.then(worker => {
                  worker.active.postMessage({
                    type: 'savePostImages',
                    data: data,
                  });
                });
              }

              this.html = editorDom.body.innerHTML;
              content.html = editorDom.body.innerHTML;
              this.sendPost(content);
            }).catch((error) => {
              console.log('Looks like there was a problem with backend: \n', error);
            });

          } else {
            this.sendPost(content);
          }
        }
      } else {
        this.sendPost(content);
      }
    },
    async sendFetch(url, data = null) {
      let formData = new FormData();
      formData.append('token', this.$store.state.usertoken);
      if (data !== null) {
        Object.keys(data).forEach(function(key) {
          formData.append(key, data[key]);
        });
      }
      return await fetch(`${this.apiPath}` + url, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        if (response.result) {
          return response.result;
        } else {
          console.log('Looks like there was a problem: \n', response.error);
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          return false;
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
        return false;
      });
    },
    sendPost(content) {
      let formData = new FormData();
      if (this.id !== 'undefined' && this.id !== undefined) formData.append('postid', this.id);
      formData.append('articleid', this.docid);
      formData.append('order', this.target);
      formData.append('content', JSON.stringify(content));
      formData.append('contenttype', this.initialType);
      formData.append('token', this.$store.state.usertoken);

      fetch(`${this.apiPath}editPost`, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        //alert(JSON.stringify(response));
        if (response.action === 'insert') {
          let postSortIds = JSON.parse(localStorage.getItem('postSortIds'));
          if (postSortIds) {
            postSortIds = postSortIds.map(item => item === 'new' ? response.postid : item);
          }
          if (postSortIds && postSortIds.length > 0) {
            this.sendFetch(
                'entity/post/resort',
                {
                  "ids": JSON.stringify(postSortIds)
                }
            ).then(() => {
              this.freeze = true;
              this.$store.commit('pageSaved');
              this.$router.go(-1);
            });
          }
        } else {
          this.freeze = true;
          this.$store.commit('pageSaved');
          this.$router.go(-1);
        }
      }).catch((error) => {
        this.$store.commit('savingError');
        console.log('Looks like there was a problem: \n', error);
      });
      this.$store.state.fromContentEdit = true;
    },
    typeSwitch(type) {
      this.futureType = type;
      if (this.initialType == type) {
        return;
      }
      if (this.initialType == 'txt') {
        if (this.html != '' && this.html != undefined) {
          this.confirm = true;
        } else {
          this.initialType = type;
          if (type == 'pdf') {
            localStorage.class = "pdf_box";
            localStorage.initialType = 'pdf';
          } else if(type == 'video') {
            localStorage.class = "video_box";
            localStorage.initialType = 'video';
          }
          localStorage.removeItem('src','html')
        }
      }
      if (this.initialType == 'pdf') {
        if (this.src != '' && this.src != undefined) {
          this.confirm = true;
        } else {
          this.initialType = type;
          if (type == 'txt') {
            localStorage.class = "txt_box";
            localStorage.initialType = 'txt';
          } else if (type == 'video') {
            localStorage.class = "video_box";
            localStorage.initialType = 'video';
          }
          localStorage.removeItem('src')
        }
      }
      if (this.initialType == 'video') {
        if (this.src != '' && this.src != undefined) {
          this.confirm = true;
        } else {
          this.initialType = type;
          if (type == 'pdf') {
            localStorage.class = "pdf_box";
            localStorage.initialType = 'pdf';
          } else if (type == 'txt') {
            localStorage.class = "txt_box";
            localStorage.initialType = 'txt';
          }
          localStorage.removeItem('src')
        }
      }
      // localStorage.initialType = type;
    },
    removeTags(index) {
      this.tags.splice(index, 1);
    },
    removeFiles(index) {
      this.$store.state.postUploadFiles.splice(index, 1);
      //this.documents.splice(index, 1);
    },
    addNewTag() {
      if (this.newTagName !== '') {
        this.tags.push(this.newTagName);
        localStorage.tags = JSON.stringify(this.tags);
        this.addTag = false;
        this.newTagName = '';
      } else {
        this.addTag = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.updown{
  .options_toggle::before,.options_toggle::after{
    width: calc(50% - 70px);
  }
}
.delete_video{
  height: 40px;
  background-color: #2C2F3596;
  width: calc(100% - 100px);
  border-radius: 20px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  span{
    display: inline-block;
    height: 100%;
    width: 40px;
    background-image: url(../../public/img/delete_white.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.page_type_desktop{
  display: flex;
  flex-wrap: wrap;
  .subheader{
    display: inline-block;
    margin: 0px;
    height: 42px;
    line-height: 42px;
    color: #787878;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
  }
}
.title{
  margin: 0 !important;
}
.toolong{
  position: absolute;
  left: 0px;
  height: 100%;
  padding: 0 3px 0 0;
  text-indent: 8px;
  background-color: #f0f0f0;
  direction: ltr;
}
.add_tag{
  width: 456px;
  margin-left: -228px;
  position: fixed;
  top: 150px;
  left: 50%;
  padding: 32px 32px 115px;
  border-radius: 16px;
  background-color: #fff;
  z-index: 3;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 14px;
  h2{
    font-size: 24px;
    margin: 0 0 32px 0;
  }
  input{
    display: block;
    margin-bottom: 16px;
    border: none;
    outline: none;
    height: 40px;
    background-color: rgba(120, 120, 120, 0.05);
    border-radius: 8px;
    text-indent: 8px;
    width: 100%;
  }
  .btns{
    text-align: center;
    position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0px;
    display: flex;
    padding: 0 32px;
    box-sizing: border-box;
    .button{
      padding: 0;
    }
    :first-child{
      width: 100px;
      margin-right: 16px;
    }
    :last-child{
      flex: 1;
    }
  }
}
.delete-upload{
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 20px;
  top: -15px;
  right: -15px;
  background-image: url(../../public/img/close_gray.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  z-index: 2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.content_edit{
  .field{
    min-height: 40px;
    margin-bottom: 16px;
    position: relative;
    height: auto;
    overflow: auto;
    p{
      display: inline-block;
      margin: 0px;
      line-height: 40px;
      font-weight: 500;
      font-size: 14px;
      width: 112px;
    }
    span{
      margin: 0;
      line-height: 40px;
      font-weight: 500;
      font-size: 14px;
      color: #405FE8;
    }
    input:not([type="file"]),select{
      background-color: rgba(120, 120, 120, .05);
      border: 0px;
      height: 40px;
      border-radius: 8px;
      position: absolute;
      // max-width: 332px;
      width: 100%;
      padding-left: 16px;
      box-sizing: border-box;
    }
    select{
      option{
        width: 200px;
      }
    }
    input[type="file"]{
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
    .add_img_label{
      color: #787878;
      background-color: rgba(120, 120, 120, .05);
      font-size: 0.76rem;
      border-radius: 8px;
      height: 39px;
      line-height: 39px;
      display: inline-block;
      padding: 0 20px;
      box-sizing: border-box;
      vertical-align: top;
      cursor: pointer;
    }
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      color: #787878;
      background-image: url(../../public/img/arr_down.svg);
      background-repeat: no-repeat;
      background-position: right center;
    }
    input:focus,input:active,select:focus,select:active{
      outline: 0px;
    }
  }
}
.files{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  .doc{
    background-color: rgba(120, 120, 120, 0.05);
    display: inline-block;
    padding: 0px 30px 0px 40px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-right: 6px;
    width: 200px;
    background-image: url(../../public/img/book/doc.svg);
    background-repeat: no-repeat;
    background-position: 14px center;
    cursor: pointer;
    background-size: 10%;
    position: relative;
    height: 39px;
    overflow: hidden;
    a{
      margin: 0px;
      font-size: 0.76rem;
      white-space: nowrap;
      overflow: hidden;
      height: 39px;
      line-height: 39px;
      text-decoration:none;
      color: #555;
    }
    p{
      margin: 0px;
      font-size: 0.76rem;
      white-space: nowrap;
      overflow: hidden;
      height: 39px;
      line-height: 39px;
    }
    .cross{
      position: absolute;
      right: 0px;
      top: 0px;
      display: inline-block;
      background-image: url(../../public/img/gray_cross.svg);
      background-repeat: no-repeat;
      background-position: center;
      height: 39px;
      width: 32px;
      background-color: #f8f8f8;
    }
  }
  .pdf_img{
    background-image: url(../../public/img/book/pdf.svg);
  }
  .img_img{
    background-image: url(../../public/img/book/img.svg);
  }
}

.uploader {
  position: absolute;
  height: 90px;
  width: 90px;
  right: -15px;
  top: 20px;
  text-align: center;
  .gaugePercent{
    font-size: 13px;
  }
}
.txt_edit{
  margin-top: 20px;
  min-width: calc(100% - 508px);
  max-width: calc(70% - 41px);
  height: calc(100% - 40px);
  @media screen and (max-width: 971px){
    height: calc(100% - 210px);
  }
  @media screen and (max-width: 1366px) and (min-width: 1024px) and (max-height: 1024px)
  and (min-height: 744px) and (orientation: landscape){
    max-width: 66%;
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 961px) and (orientation: portrait) {
    max-width: none;
  }
  @media screen and (max-width: 599px){
    max-width: none;
  }
}
.video_edit,.pdf_edit{
  margin-top: 20px;
  position: relative;
  width: calc(70% - 20px);
  height: calc(100% - 40px);
  .video_box,.pdf_box{
    border-radius: 16px;
    position: relative;
    margin-bottom: 24px;
    margin-right: 500px;
    video{
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
    }
    iframe{
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
    }
  }
  .pdf_box{
    max-height: 73vh;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.video_edit{
  .video_box{
    width: 100%;
    iframe{
      border-radius: 20px;
    }
  }
}
.edit_header{
  height: 64px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 1; 
  top: 0;
  left: 0;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
  padding: 16px 32px;
  box-sizing: border-box;
  button{
    border: 0;
    background-color: #fff;
    outline: none;
  }
  .save{
    right: 32px;
    height: 64px;
    color: #787878;
  }
  .cancel{
    right: 105px;
    height: 64px;
    color: #787878;
  }
  .delete{
    left: 20px;
    height: 64px;
    display: inline-block;
    color: #CD180A;
  }
  .delete,.cancel,.save{
    position: absolute;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Inter';
    top: 0px;
    font-size: 1rem;
  }
}
  
.title_warning{
  position: absolute;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  padding: 32px;
  border-radius: 16px;
  background-color: #fff;
  z-index: 3;
  box-sizing: border-box;
  width: 370px;
  max-width: calc(100% - 32px);
  height: 320px;
  h3{
    text-align: left;
    margin: 0 0 32px 0;
  }
  p{
    text-align: left;
    font-size: 0.82rem;
    margin: 0 auto;
    color: #787878
  }
  .btns{
    text-align: center;
    position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0px;
    padding: 0 32px;
    box-sizing: border-box;
    .button{
      width: 100%;
      min-width: 160px;
    }
  }
}
  .page_info{
    position: absolute;
    max-width: 30%;
    width: 508px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #fff;
    padding: 0px 32px 32px 32px;
    box-sizing: border-box;
    z-index: 2;
    .options_toggle::before,.options_toggle::after{
      width: calc(50% - 110px);
    }
    .tag{
      flex-direction: column;
      p{
        line-height: 1.2rem;
        height: 1.2rem;
        width: 100%;
        margin-bottom: 8px;
      }
      input{
        min-height: 39px;
      }
    }
    >div{
      margin-top: 20px;
      .subheader{
        color: #787878;
        font-size: 1rem;
        font-weight: 400;
        flex-grow: 1
      }
      input:not([type="checkbox"]){
        background-color: rgba(120, 120, 120, .05);
        border: 0px;
        height: 40px;
        border-radius: 8px;
        max-width: 332px;
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
      }
      input:focus,input:active{
        outline: 0px;
      }
      input[type="file"]{
        opacity: 0;
        visibility: hidden;
        position: absolute;
      }
      .add_label{
        color: #787878;
        background-color: rgba(120, 120, 120, .05);
        font-size: 12px;
        border-radius: 8px;
        height: 32px;
        line-height: 32px;
        display: inline-block;
        padding: 0 20px;
        cursor: pointer;
      }
      .hashtags{
        display: inline-block;
        div{
          padding: 0 20px;
          display: inline-block;
          cursor: pointer;
          background-color: rgba(40, 132, 199, 0.05);
          color: #2884C7;
          margin-right: 8px;
          margin-bottom: 8px;
          border-radius: 8px;
          span{
            font-size: 12px;
            height: 32px;
            line-height: 32px;
            padding-right: 20px;
            background-image: url(../../public/img/blue_cross.svg);
            background-repeat: no-repeat;
            background-position: center right;
          }
        }
      }
    }
  }
  .page_type{
    font-size: 0.76rem;
    font-weight: 500;
    line-height: 39px;
    height: 39px;
    padding: 0px 16px;
    border-radius: 8px;
    display: inline-block;
    color: #787878;
    background-color: rgba(120, 120, 120, 0.05);
    cursor: pointer;
    margin-right: 8px;
  }
  .page_type:last-child{
    margin-right: 0px;
  }
  .active_pt{
    color: #fff;
    background-color: #006C3E;
  }
  .content_edit{
    bottom: 0px;
    top: 64px;
    .title{
      margin: 32px 0 20px 0;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 1300px) and (orientation: landscape){
    .page_info{
      max-width: 35%;
      padding: 0px 20px 32px 20px;
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 961px) and (orientation: portrait) {
    .page_info{
      z-index: 3;
    }
    .edit_button{
      height: 44px;
      width: 44px;
      display: inline-block;
      background-image: url(../../public/img/page/edit.svg);
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 62px;
      right: 9px;
      background-size: 26px;
    }
    .subtitle{
      line-height: 16px;
      margin-bottom: 0px;
    }
    .txt_edit,.pdf_edit,.video_edit{
      max-height: 83%;
      width: 100%;
    }
    .type_choose_mobile{
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      p{
        display: inline-block;
        margin: 0px;
        font-weight: 500;
        color: #787878;
        flex-grow: 1;
        height: 32px;
        line-height: 32px;
      }
    }
    .content_edit{
      .title{
        margin: 8px 0 20px 0;
      }
    }
    header{
      height: 64px !important;
    }
    .main_v8{
      top: 64px !important;
    }
    .video_edit,.pdf_edit{
      max-width: inherit;
      .video_box,.pdf_box{
        margin-right: 0px;
      }
    }
    .content_edit{
      overflow-y: hidden;
      .title{
        width: 100%;
      }
    }
    .active_info{
      bottom: -650px !important;
    }
    .page_info{
      position: absolute;
      max-width: none;
      width: 100%;
      top: initial;
      bottom: 0px;
      right: 0px;
      background-color: #fff;
      padding: 0px 32px 32px 32px;
      box-sizing: border-box;
      box-shadow: 0px -2px 8px 2px rgba(0, 0, 0, 0.06);
      border-radius: 24px 24px 0px 0px;
      transition: bottom .3s;
      >div{
        margin-top: 16px;
        .subheader{
          display: inline-block;
          width: 152px;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 900px) and (orientation: portrait) {
    .title,.subtitle{
      display: block;
    }
    .content_edit{
      padding: 0 16px;
    }
    .page_info{
      padding: 16px 16px;
      // height: 725px;
    }
  }
  // IPAD PRO
  @media screen and (max-width: 1366px) and (min-width: 1024px) and (max-height: 1024px)
  and (min-height: 744px) and (orientation: landscape){
    .page_info{
      max-width: 33%;
      padding: 0px 24px 32px 24px;
    }
  }
  // IPAD MINI
  @media screen and (width: 1024px) and (height: 768px) and (orientation: landscape){
    .page_info{
      max-width: 390px;
    }
  }
  // SURFACE PRO 7
  @media screen and (width: 1368px) and (height: 912px) and (orientation: landscape){
    .form{
      max-width: 800px;
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
    .updown{
      .options_toggle::before,.options_toggle::after{
        width: calc(50% - 100px);
      }
    }
    .delete_video{
      height: 30px;
      width: calc(100% - 30px);
      border-radius: 15px;
      top: 10px;
    }
    .page_info{
      z-index: 3;
    }
    .title,.subtitle{
      display: block;
    }
    .txt_edit,.video_edit,.pdf_edit{
      width: 100%;
      height: 84%;
    }
    .content_edit{
      top: 44px;
      .subtitle{
        line-height: 16px;
        height: 16px;
        margin: 7px 0px 0px 0px;
      }
      .title{
        margin: 0px 0 7px 0;
        font-size: 22px;
        line-height: 32px;
        height: 32px;
      }
    }
    .edit_button{
      height: 44px;
      width: 44px;
      display: inline-block;
      background-image: url(../../public/img/page/edit.svg);
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 51px;
      right: 9px;
    }
    .edit_header{
      height: 44px;
      .save{
        height: 44px;
        right: 10px;
      }
      .cancel{
        height: 44px;
        right: 76px;
      }
      .delete{
        height: 44px;
        left: 10px;
        right: auto;
        span{
          display: none;
        }
      }
    }
    .title_warning{
      height: auto;
      width: 90%;
      margin-left: 0px;
      padding: 20px 20px 88px 20px;
      h3{
        margin: 0 0 16px 0;
      }
      p{
        text-align: left;
        padding: 10px 0 10px;
      }
      .trash_can{
        background-size: 70%;
        margin: 0 auto 10px;
        height: 170px;
      }
      .btns{
        padding: 20px 0px 0px;
        bottom: 20px;
        .button{
          width: calc(100% - 40px);
        }
      }
    }
    .content_edit{
      overflow-y: hidden;
      >h2{
        width: calc(100% - 32px);
      }
      .field{
        margin-bottom: 0px;
      }
    }
    .video_edit,.pdf_edit{
      max-width: none;
      margin-top: 16px;
      .video_box,.pdf_box{
        margin-right: 0px;
        padding: 0px;
        width: 100%;
        background: none;
        iframe{
          border-radius: 18px;
          overflow: hidden;
        }
      }
    }
    .type_choose_mobile{
      margin-top: 10px;
      position: relative;
      height: 39px;
      p{
        display: inline-block;
        margin: 0px;
        font-size: 1rem;
        font-weight: 500;
        color: #787878;
        height: 39px;
        line-height: 39px;
      }
      .page_type{
        padding: 0px 16px;
        margin-right: 0px;
        margin-left: 8px;
        position: absolute;
      }
      div:nth-child(2){
        right: 130px;
      }
      div:nth-child(3){
        right: 72px;
      }
      div:last-child{
        right: 0px;
      }
    }
    .active_info{
      bottom: -500px !important;
    }
    .page_info{
      position: absolute;
      max-width: none;
      width: 100%;
      top: initial;
      bottom: 0px;
      right: 0px;
      background-color: #fff;
      padding: 0px 16px 60px 16px;
      box-sizing: border-box;
      box-shadow: 0px -2px 8px 2px rgba(0, 0, 0, 0.06);
      border-radius: 24px 24px 0px 0px;
      transition: bottom .3s;
      .tag{
        display: flex;
        flex-direction: column;
        p{
          width: 100%;
          margin: 0px;
          display: inline-block;
          font-weight: 500;
          height: 1.2rem;
          line-height: 1.2rem;
          margin-bottom: 8px;
        }
        input{
          flex: 1;
          box-sizing: border-box;
          min-height: 39px;
          max-width: 100% !important;
        }
        input,p{
          // line-height: 39px;
          color: #787878;
        }
      }
      .divider{
        display: inline-block;
        height: 2px;
        background-color: rgba(37, 37, 37, 0.1);
        margin: 24px 0;
        width: 100%;
      }
      .delete_page_button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        background-color: #006C3E;
        line-height: 40px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        color: #fff;
        border-radius: 8px;
        margin: 0 0 24px 0;
        margin-top: 0px !important;
        span{
          display: inline-block;
          height: 40px;
          width: 40px;
          background-image: url(../../public/img/page/delete_white.svg);
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      >div{
        margin-top: 10px;
        input:not([type="checkbox"]){
          width: 100%;
        }
        .subheader{
          display: inline-block;
          line-height: 39px;
          height: 39px;
          margin: 0 20px 0 0;
          color: #787878;
          font-weight: 500;
        }
      }
    }
    .files{
      width: 100%;
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait){
  }
  @media screen and (width: 1024px) and (height: 768px), screen and (width: 1180px) and (height: 820px){
  }
</style>
